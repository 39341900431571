<script>
import Star from "@/views/Recomendation/Part/Star.vue";

export default {
  name: "Legend",
  components: {Star},
  data: () => ({
    legend: {
      '1': 'pomyśl nad alternatywą',
      '2': 'na własne ryzyko',
      '3': 'możesz spróbować',
      '4': 'jest dobrze',
      '5': 'i właśnie o to chodzi!',
    }
  })
}
</script>

<template>
  <div>
    <b>Legenda</b>

    <ul>
      <li><Star :recomendation="{review_star: 0}" class="mr-3"/>nie warto tracić czasu</li>
      <li v-for="(text, key) in legend" :key="key">
        <Star :recomendation="{review_star: key}" class="mr-3"/>
        {{ text }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

span {

  color: #1d4cff;
  letter-spacing: 2px;
  z-index: 2;
  overflow: hidden;

  margin-right: 4px;

}

</style>
